.main-page-body {
  position: relative;
  display: grid;
  grid-template: min-content 1fr min-content / 1fr;
  padding: 9px 20px 0;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
}

.main-page-gradient {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.main-page-content {
  width: 100%;
  padding: 0;
  margin-top: 86px;
}

.main-page-heading-container{
  margin: 0 auto;
  max-width: 510px;
}

.main-page-headings-common {
  font-weight: 700;
  margin: 0;
  color: #FFFFFF;
  text-align: center;
  z-index: 1;
}

.main-page-heading {
  font-size: 54px;
  line-height: 63.5px;
}

.main-page-heading-small {
  font-size: 32px;
  line-height: 40px;
}

.main-page-claim {
  margin: 120px auto 0;
  max-width: 841px;
}

.main-page-claim-text {
  font-size: 32px;
  line-height: 42px;
}

.main-page-claim-btn-container {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.claim-button-width {
  width: 249px;
}

.main-page-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  color: white;
  padding: 12px 18px 12px 18px;
  font-weight: 700;
  border-radius: 80px;
  box-shadow: inset white 0px 0px 5px;
  background: radial-gradient(
          circle,
          rgba(103, 73, 244, 1) 29%,
          rgba(143, 124, 255, 1) 58%,
          rgba(227, 196, 255, 1) 100%
  )
}

.main-page-button:hover {
  color: #ffffff !important;
  box-shadow: 0px 0px 30px 4px #ffffff72, 0px -1px 6px 0px #ffffffcf inset,
  0px 2px 4px 0px #ffeec159 inset !important;
}

.main-page-claim-btn-icon {
  width: 16px;
  height: 16px;
  margin-right: 16px;
}

.main-page-info-container {
  margin: 70px auto 0;
  width: 100%;
  max-width: 1196px;
  display: flex;
}

.main-page-info-left {
  width: 100%;
  max-width: 453px;
  display: flex;
  flex-direction: column;
}

.main-page-info-right {
  width: 100%;
  max-width: 772px;
  margin-left: 21px;
}

.main-page-info-card-bg {
  background-color: #FFFFFF10;
  border-radius: 20px;
}

.main-page-info-transactions-card {
  padding: 38px 80px 28px 39px;
}

.main-page-info-fee-rebate-card {
  margin-top: 19px;
  padding: 28px 77px 28px 39px;
  z-index: 3;
}

.fee-rebate-card-date-picker{
  margin-top: 16px;
}

.row-between {
  display: flex;
  justify-content: space-between;
}

.card-text-small {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF
}

.card-text-big {
  margin: 8px 0 0 0 !important;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  color: #FFFFFF
}

.card-bottom-text-container {
  display: flex;
  justify-content: center;
  margin-top: 34px;
}

.card-bottom-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 280px;
}

.card-bottom-text-1 {
  margin: 0 !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #A7A3C2
}

.card-bottom-text-1-span {
  color: #FFFFFF;
  font-weight: 700;
}

.card-tabs {
  width: 100%;
  display: flex;
  max-width: 337px;
}

.card-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 79px;
  height: 32px;
  margin-left: 8px;
  background-color: #FFFFFF10;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  border-radius: 8px;
  cursor: pointer;
}

.card-tab:first-child {
  margin-left: 0;
}

.card-tab-active {
  font-weight: 700;
  background: linear-gradient(162deg, rgba(129,107,219,1) 0%, rgba(175,128,212,1) 100%);
}

.fees-info {
  margin-top: 36px;
}

.fees-info-row {
  display: flex;
  align-items: center;
  margin-top: 14px;
}

.fees-info-row:first-child {
  margin-top: 0;
}

.fees-info-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.fees-info-text {
  color: #FFFFFF;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
}

.chart-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 34px 39px 0px;
  position: relative;
}

.chart-card-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 99;
}

.cart-container {
  width: 100%;
  height: 376px;
}

.chart-tooltip {
  padding: 8px 0px 8px 16px;
  position: absolute;
  width: 204px;
  min-height: 168px;
  height: auto;
  border-radius: 16px;
  background-color: #292637;
  z-index: 1;
}

.border-mask::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px !important;
  border: 1px solid transparent;
  background: linear-gradient(359deg, transparent, rgba(180, 168, 225, 0.3))
  border-box;
  -webkit-mask: linear-gradient(#B4A8E1 0 0) padding-box, linear-gradient(#887ABD 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.color-white {
  color: #FFFFFF;
}

.color-secondary {
  color: #A7A3C2;
}

.card-tooltip-text {
  margin: 0 !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
}

.card-tooltip-row-between {
  padding-right: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-tooltip-fee-info {
  display: flex;
  flex-wrap: wrap;
}

.card-tooltip-fee-info-block {
  margin-top: 8px;
  margin-right: 14px;
  display: flex;
  align-items: center;
}

.card-tooltip-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.main-page-footer {
  margin-top: 137px;
  margin-bottom: 14px;
}

.empty-fee-state-container {
  margin-top: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: -1;
}

.empty-fee-state-text {
  width: 100%;
  margin-top: 16px;
  margin-right: -40px;
  max-width: 260px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
}

.empty-fee-state-images {
  width: 68px;
  height: 48px;
  position: relative;
  margin-right: -40px;
  z-index: -1;
}
.empty-fee-state-bg-img {
  position: absolute;
  z-index: -1;
  width: 141px;
  height: 107px;
  top: -35px;
  left: -46px;
}

.empty-fee-state-folder-img {
  position: absolute;
  left: 0;
  width: 44px;
  height: 38px;
}
.empty-fee-state-mug-img {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 26px;
  top: -10px;
}
.empty-chart-state {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.empty-chart-state-text {
  width: 100%;
  margin-top: -60px;
  text-align: center;
  max-width: 277px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
}

.empty-chart-state-img {
  position: relative;
  width: 360px;
  height: 260px;
}

.empty-chart-state-bg-img {
  width: 100%;
  height: 100%;
}
.empty-chart-state-diagram-img {
  position: absolute;
  top: 10px;
  left: 52px;
  width: 77%;
  height: 85%;
}

@media (max-width: 1090px) {
  .mt-16 {
    margin-top: 16px !important;
  }
  .chart-card-top {
    flex-direction: column;
  }
  .chart-card {
    padding: 26px 22px 0px
  }
}

@media (max-width: 880px) {
  .main-page-info-container {
    flex-direction: column;
    align-items: center;
    margin-top: 48px;
  }
  .main-page-info-right {
    margin-left: 0;
    margin-top: 16px;
    max-width: unset;
  }
  .main-page-info-transactions-card {
    padding: 35px 34px 29px 23px;
  }
  .main-page-info-fee-rebate-card {
    margin-top: 16px;
    padding: 29px 23px 19px;
  }
  .main-page-info-left {
    max-width: unset;
  }
  .chart-card-top {
    flex-direction: row;
  }
}

@media (max-width: 768px) {
  .main-page-content {
    margin-top: 49px;
  }
  .main-page-heading {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }
  .main-page-heading-small {
    font-size: 18px;
    line-height: 24px;
  }
  .main-page-claim-text {
    font-size: 20px;
    line-height: 28px;
  }
  .main-page-claim {
    margin-top: 60px;
  }
  .main-page-footer {
    margin-top: 60px;
  }
  .main-page-claim-btn-container {
    margin-top: 32px;
  }
}

@media (max-width: 580px) {
  .chart-card-top {
    flex-direction: column;
  }
  .card-tab {
    width: fit-content;
    padding-inline: 12px;
  }
  .cart-container {
    height: 324px;
  }
  .main-page-claim {
    max-width: 335px;
  }
  .mobile-small-text {
    font-size: 12px !important;
  }
  .mobile-big-text {
    font-size: 24px !important;
    line-height: 32px !important;
  }
  .mobile-line-height-20 {
    line-height: 20px !important;
  }
  .chart-tooltip {
    min-height: 128px;
  }
  .card-tooltip-text {
    font-size: 10px;
    line-height: 16px;
  }
  .empty-fee-state-text,
  .empty-fee-state-images {
    margin-right: -10px;
  }
  .empty-chart-state-img {
    width: 294px;
    height: 232px;
  }
  .empty-chart-state-text {
    margin-bottom: 32px;
  }
  .empty-chart-state-diagram-img {
    top: 6px;
    left: 34px;
  }
}