.card-calendars-container {
    position: relative;
    width: 100%;
    margin-top: 8px;
    display: flex;
    max-width: 338px;
}

.card-calendar {
    position: relative;
    width: 164px;
    display: flex;
    justify-content: space-between;
    padding: 6px 14px 6px 16px;
    border-radius: 10px;
    background-color: #FFFFFF10;
    cursor: pointer;
    margin-left: 8px;
}

.calendar-active {
    border: 1px solid #ffffff72;
}

.card-calendar:first-child {
    margin-left: 0;
}

.calendar-text-container {
    width: fit-content;
}

.calendar-text-small {
    margin: 0 !important;
    color: #B5B2C9;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
}

.calendar-text-big {
    margin: 0 !important;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
}

.calendar-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.calendar-icon {
    width: 16px;
    height: 16px;
}

.calendar-window {
    position: absolute;
    top: 54px;
    width: 331px;
    max-width: 332px;
    height: 440px;
    padding: 24px 19px;
    background-color: #292637;
    border-radius: 20px;
    cursor: auto;
}

.calendar-months-row {
    position: relative;
    display: flex;
    justify-content: space-between;
}

.calendar-arrow {
    width: 18px;
    height: 18px;
    cursor: pointer;
}
.calendar-month-name {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}
.calendar-button-width {
    width: 212px;
}
.calendar-buttons {
    margin-top: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 100;
}
.reset-button {
    margin-top: 12px;
    cursor: pointer;
}

.calendar-days-container {
    position: relative;
    height: 258px;
    margin-top: 8px;
}
.days-row {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.calendar-text-item {
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 41px;
    height: 30px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.days-color {
    color: #7D779E;
}

.pointer {
    cursor: pointer;
}

.day-is-active-left {
    color: #292637;
    background-color: #FFFFFF;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}

.day-is-active-right {
    color: #292637;
    background-color: #FFFFFF;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.day-active-range {
    background-color: #FFFFFF10;
}

.day-disabled {
    opacity: 0.2;
    cursor: auto;
    pointer-events: none;
}

.calendar-modal-container {
    padding-top: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 880px) {
    .calendar-window {
        left: -20px
    }
}

@media (max-width: 580px) {
    .calendar-window {
        left: 0;
    }
    .card-calendar {
        width: 140px;
    }
    .calendar-text-big {
        font-size: 10px;
    }
}