:root {
  --eye-icon-ratio: 18px;
}


.loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px;
  width: 100%;
  margin: 60px auto 0;

}

.loginFormWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
}

.loginFormInputWrapper {
  position: relative;
  width: 100%;
}
.loginError {
  color: red;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
.loginFormInput {
  width: 100%;
  height: 50px;
  padding: 16px 20px;
  background: rgba(255, 255, 255, 0.10);
}

.loginFormInputPass {
  padding-right: calc(20px + 18px);
}
.loginEye {
  width: var(--eye-icon-ratio);
  height: var(--eye-icon-ratio);
  position: relative;
  cursor: pointer;
}
.loginEye::after {
  content: '';
  position: absolute;
  width: var(--eye-icon-ratio);
  height: 1px;
  background-color: #fff;
  border-radius: 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  transition: opacity 300ms ease;
  opacity: 0;
  z-index: 1;
}
.loginEye--show::after {
  opacity: 1;
}
.loginEye img {
  display: block;
}
.loginFormShowHideBtn {
  position: absolute;
  bottom: 16px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  padding: 0;
  margin: 0;
  border:0;
  background-color: unset;
}

.loginFormLabel {
  margin-bottom: 8px;
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px; 
}

.loginFormInput::placeholder {
  color: #A39DC7;
}

.loginFormSubmit {
  margin: 0;
  width: 100%;
}

.loginFormText {
  margin: 0;
  color: #CAC6DF;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}



@media (min-width: 768px) {
  .loginForm {
    width: 424px;
  }
}