.footerBtn {
  border-radius: 10px;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  text-decoration: none;
}

.infoBlock {
  background-color: #ffffff10;
  border-radius: 15px;
  overflow: hidden;
  transition: height 300ms ease-out;
}

.powerIconSize {
  width: 14px;
  height: 14px;
  margin-right: 13px;
}

.power-icon-display {
  display: block;
}
.power-icon-white-display {
  display: none;
}

.dropContainerDiv {
  position: absolute;
  top: 110%;
  width: 100%;
  height: 0px;
}

.receiveBlock {
  padding: 20px 18px 21px 17px;
  margin-bottom: 24px;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -90px 0 0 -90px;
}

.loader .circle {
  position: absolute;
  border: 3px solid transparent;
  border-top-color: hsl(252, 84%, 73%);
  border-radius: 50%;
  animation: rotate linear infinite;
  background-color: transparent;
}

.loader .circle.one {
  height: 50px;
  width: 50px;
  left: 50px;
  top: 50px;
  animation-duration: 0.85s;
}

.loader .circle.two {
  height: 75px;
  width: 75px;
  top: 38px;
  left: 38px;
  animation-duration: 0.95s;
}

.loader .circle.three {
  height: 100px;
  width: 100px;
  top: 25px;
  left: 25px;
  animation-duration: 1.05s;
}

@keyframes rotate {
  from {
    transform: rotateZ(360deg);
  }

  to {
    transform: rotateZ(0deg);
  }
}

@media only screen and (max-width: 480px) {
  .power-icon-display {
    display: none;
  }
  .power-icon-white-display {
    display: block;
  }
}
.absoluteCenter .modal-dialog {
  position: absolute;
  transform: translate(-50%, -50%) !important;
  left: 50%;
  top: calc(50% + 35px);
}
