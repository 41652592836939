.loginBody {
  position: relative;
  display: grid;
  grid-template: min-content 1fr min-content / 1fr;
  padding: 9px 20px 0;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
}

.loginBgTop {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.loginContent {
  width: 100%;
  padding: 0;
  margin-top: 104px;
}

.loginHeading {
  margin: 0;
  color: #FFF;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  z-index: 1;
}

.loginFooter {
  margin-bottom: 14px;
}

@media (min-width: 768px) {
  .loginContent {
    margin-top: 126px;
  }

  .loginHeading {
    font-size: 36px;
    font-weight: 700;
    line-height: 32px;
  }
}