body {
  margin: 0;
  font-family: Satoshi, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  max-width: 100vw;
  overflow: auto;
  font-size: 1rem;
  scrollbar-width: thin;
  scrollbar-color: #715aff;
}

body::-webkit-scrollbar-thumb {
  background-color: #715aff;
}

body::-webkit-scrollbar-track {
  background-color: #715aff40;
}

html {
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    Satoshi-Medium, Satoshi-Regular, Satoshi-Bold, monospace;
}

/*INPUTS AND BUTTONS*/

input {
  font: inherit;
  background-color: #ffffff20;
  border-width: 0px;
  height: 4rem;
  border-radius: 15px;
  color: #ffffff;
  font-size: 1rem;
  padding: 10px;
  padding-left: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-block: 0px;
}

input:focus {
  outline: none !important;
  border: 0px solid #ffffff20;
  box-shadow: 0 0 0px #719ece;
}

input,
textarea {
  &::placeholder {
    text-transform: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    transition: 0.4s linear;
    color: #ffffff;
  }
}

.text-gradient {
  background: -webkit-linear-gradient(45deg, #8f7cff, #ebd2ff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn:hover {
  box-shadow: white 0px 0px 1px;
  transition: all 0.5s ease;
  color: #ffffff90;
}

.btn:active {
  box-shadow: white 0px 0px 9px;
  transition: all 0.5s ease;
}

.dropdown-item:hover {
  background-color: #8f7cff;
  border-radius: 20px;
  transition: all 0.3s ease;
}

input:focus {
  border-width: 0;
}

input::-webkit-input-placeholder {
  color: #ffffff;
}

input::-moz-placeholder {
  color: #ffffff;
}

input::-moz-placeholder {
  color: #ffffff;
}

input::-ms-input-placeholder {
  color: #ffffff;
}

input::placeholder {
  color: #ffffff;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus::-ms-input-placeholder {
  color: transparent;
}

input:focus::placeholder {
  color: transparent;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
  appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.modal-dialog {
  margin: auto;
}

.input-group-lg > .btn,
.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text {
  font-size: 0.9rem !important;
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-lg > .btn,
.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text {
  font-size: 0.9rem !important;
}

.input-group-lg > .btn,
.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text {
  border-radius: 15px;
}

.networkIcon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
}

table {
  color: #ffffff;
  border-color: transparent;
}

.table {
  --bs-table-color-type: none;
  --bs-table-bg-type: none;
  --bs-table-color-state: none;
  --bs-table-bg-state: none;
  --bs-table-color: white;
  --bs-table-bg: none;
  --bs-table-border-color: none;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: transparent;
  --bs-table-striped-bg: transparent;
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: #ffffff10;
  --bs-table-hover-color: #ffffff;
  --bs-table-hover-bg: #ffffff00;
  border-color: transparent;
}

.scrollBar {
  scrollbar-track-color: #715aff;
}

@media only screen and (max-width: 480px) {
  .networkIcon {
    width: 19px;
    height: 19px;
    border-radius: 15px;
  }
}

/*@font-face {*/
/*  font-family: 'rocgrotesk-medium';*/
/*  src: url('./Assets/fonts/rocgrotesk-medium.otf') format('otf');*/
/*  font-weight: 400;*/
/*  font-display: swap;*/
/*  font-style: normal;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Satoshi-Variable';*/
/*  src: url('./Assets/fonts/Satoshi-Variable.woff') format('woff');*/
/*  font-weight: 300 900;*/
/*  font-display: swap;*/
/*  font-style: normal;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Satoshi-Light';*/
/*  src:*/
/*    url('./Assets/fonts/Satoshi-Light.woff') format('woff');*/
/*  font-weight: 300;*/
/*  font-display: swap;*/
/*  font-style: normal;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Satoshi-Regular';*/
/*  src:*/
/*    url('./Assets/fonts/Satoshi-Regular.woff') format('woff');*/
/*  font-weight: 400;*/
/*  font-display: swap;*/
/*  font-style: normal;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Satoshi-Medium';*/
/*  src:*/
/*    url('./Assets/fonts/Satoshi-Medium.woff') format('woff');*/
/*  font-weight: 500;*/
/*  font-display: swap;*/
/*  font-style: normal;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Satoshi-Bold';*/
/*  src:*/
/*    url('./Assets/fonts/Satoshi-Bold.woff') format('woff');*/

/*  font-weight: 700;*/
/*  font-display: swap;*/
/*  font-style: normal;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Satoshi-Black';*/
/*  src:*/
/*    url('./Assets/fonts/Satoshi-Black.woff') format('woff');*/

/*  font-weight: 900;*/
/*  font-display: swap;*/
/*  font-style: normal;*/
/*}*/
