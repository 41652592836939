.App {
  text-align: center;
}

* {
  font-family: Satoshi-Medium, sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.btnGradientMain {
  margin-top: 39px;
  color: white;
  width: 208px;
  height: 50px;
  padding: 12px 18px 12px 18px;
  border-radius: 80px;

  font-weight: 700;
  box-shadow: inset white 0px 0px 5px;
  background: radial-gradient(
          circle,
          rgba(123, 73, 244, 1) 34%,
          rgba(143, 124, 255, 1) 86%,
          rgba(227, 196, 255, 1) 100%
  );
}

.btnGradientMain:hover {
  color: #ffffff !important;
  box-shadow: 0px 0px 30px 4px #ffffff72, 0px -1px 6px 0px #ffffffcf inset,
  0px 2px 4px 0px #ffeec159 inset !important;
}

.terms-privacy-text {
  margin-left: 50px;
  cursor: pointer;
}

.link-gradient {
  background: linear-gradient(91deg, #E7C8FF 60.08%, #8F7CFF 83.72%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-decoration-line: underline;
  border-bottom: 1px solid;
  border-image: linear-gradient(91deg, #E7C8FF 60.08%, #8F7CFF 83.72%) 1;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  padding: 0 10px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.logo-img {
  width: 20px !important;
  height: 20px !important;
}

.infoDiv {
  border-radius: 15px;
  height: 4rem;
  background-color: #ffffff20;
  display: flex;
  align-items: center;
  padding-inline: 16px;
  width: 100%;
}

.info-block-price-div {
  text-align: left;
  width: 80px;
}

.mb-16 {
  margin-bottom: 16px;
}

.clipPath {
  clip-path: circle(34px at 0 100px);
}

.form-max-width {
  width: 100%;
  max-width: 474px;
}


.formBg {
  /*backdrop-filter: blur(10px);*/
  background-color: rgba(255, 255, 255, 0.1);
  transition: height 0.3s ease;
  justify-content: center;
  align-items: center;
  /*border: #ffffff40 0.6px solid;*/
  margin-top: 81px;

  margin-bottom: 98px;
}

.statusForm {
  padding: 77px 34px;
  width: 474px;
  height: 610px;
}

.gradient-border-mask {
  position: relative !important;
}

.gradient-border-mask::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px !important;
  border: 1px solid transparent;
  background: linear-gradient(359deg, transparent, rgba(255, 255, 255, 0.6))
    border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.defaultRadius {
  border-radius: 20px !important;
}

.headerArrow {
  height: fit-content;
  width: fit-content;
  margin-left: 10px;
  margin-top: -2px;
}

.page-background-color {
  background-color: #000000;
}

.formGradient {
  position: absolute;
  width: 100%+2px;
  bottom: -1px;
  left: -2px;
  right: -2px;
  height: 70%;
  background-image: linear-gradient(#00000000, #00000060);
}

.transitionHeight {
  transition: height 300ms ease-out;
}

.formBody {
  position: relative;
  top: 0.5px;
  box-sizing: border-box;
  padding: 34px 30px 23px 38px;
}

.mainSendModal {
  /* background-size: cover; */
  background: radial-gradient(ellipse at top, #6555b9, #1a1a1a);
}

.mainSendModal-bottom-shadow {
  background: linear-gradient(180deg, #6555b9, #1a1a1a);
}

.separator {
  height: 2px;
  width: 95%;
  background: radial-gradient(#ffffff1f 0%, #ffffff00 100%);
  align-self: center;
  margin: 24px 0;
}

.mobile-menu-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: #030016;
  z-index: 2;
}

.mobile-menu-background {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.mobile-menu-content {
  padding: 37px 18px 0 20px;
}

.mobile-menu-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile-menu-tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 65px;
  text-align: center;
}

.mobile-menu-tabs-item {
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
}
.mobile-menu-tabs-item:not(:first-child) {
  margin-top: 52px;
}

.mobile-menu-footer {
  margin-top: 84px;
  display: flex;
}

.mobile-menu-footer-btn {
  border-radius: 6px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
}

.mobile-menu-footer-btn img {
  width: 18px;
  height: 18px;
}

.mobile-menu-footer-btn:not(:first-child) {
  margin-left: 8px;
}

.imgDiv20 {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.margin-top {
  margin-top: 24px;
}

.dropdown::after {
  display: none;
}

.dropdown-menu.show {
  padding-inline: 11px !important;
  padding-block: 7px !important;
  width: 211px !important;
  border-radius: 12px;
  background: linear-gradient(172.41deg, #3e3b50 -27.46%, #1e1d24 101.26%);
  box-shadow: 5px 5px 10px 0px #00000066;
  margin-top: 9px;
}

.transferInfoTitle {
  font-size: 18px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  font-family: Satoshi-Bold;
}

.transferinfoSubyitle {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 24px;
  margin-bottom: 8px;
}

.transactionInfoDableDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.transactionInfoDableDivCircle {
  width: 24px;
  height: 24px;
  border-radius: 20px;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #b07fe0;
  background-image: linear-gradient(130deg, #7c82ff, #b07fe0);
  border: none;
}

.textSecondary {
  color: #9d98be;
}

.searchIcon {
  position: absolute;
  align-self: center;
  left: 1rem;
  height: 18px;
  width: 18px;
}

.gradient-border-mask-connect-wallet {
  position: relative !important;
}

.gradient-border-mask-connect-wallet::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px !important;
  border: 1px solid transparent;
  background: linear-gradient(359deg, transparent, rgba(255, 255, 255, 0.6))
    border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.smallCircleBtn {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  justify-content: center;
  display: flex;
  align-items: center;
  transition: all 300ms ease-out;
}

.smallCircleBtnCoinIcon {
  background-color: #ffffff;
  margin-right: 10px;
}

.coinImg {
  width: 16px;
  height: 16px;
}

.logout-button {
  color: #FF9EB6 !important;
  cursor: pointer;
}

.logout-icon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.header-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: 99px;
  background: transparent;
  border: none;
  outline: none;
  animation: opacityIn 1s ease;

  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.cursor {
  cursor: pointer;
}

.networkInfoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.footer-mt {
  margin-top: auto;
}

.networkInfoLeftDiv {
  display: flex;
  align-items: center;
}

.networkInfoNetIcon {
  width: 16px;
  height: 16px;
}

.infoText {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.infoText-bold {
  font-weight: 700 !important;
}

.page-padding-bottom {
  padding-bottom: 31px !important;
}

.mainSend {
  background-size: cover;
  position: relative;
  /*background-image: radial-gradient(circle at 57% 5%, rgba(158,134,255,1) -11%, rgba(0,0,0,0) 36%),*/
  /*                  radial-gradient(circle at 34% -1%, rgba(22,19,140,1) 0%, rgba(0,0,0,0) 27%);*/

  /*background-image: radial-gradient(circle at 55% 1%, rgba(158,134,255,1) -20%, rgba(0,0,0,0) 37%),*/
  /*                   !*radial-gradient(circle at 50% 1%,  rgba(112,88,255,1) 0%, rgba(0,0,0,0) 37%), *!*/
  /*                  radial-gradient(circle at 40% -1%, rgba(22,19,140,1) 0%, rgba(0,0,0,0) 25%);*/

  /*background-image: radial-gradient(circle at 55% 1%, rgba(158,134,255,1) -29%, rgba(0,0,0,0) 45%),*/
  /*radial-gradient(circle at 35% -24%, rgba(22,19,140,1) 18%, rgba(0,0,0,0) 38%);*/
  padding-inline: 40px;
  overflow: hidden;
  z-index: 1;
}

.formModalBg {
  /*backdrop-filter: blur(10px);*/
  background-color: rgba(255, 255, 255, 0.1);
  transition: height 0.3s ease;
  justify-content: center;
  align-items: center;
  /*border: #ffffff40 0.6px solid;*/
  margin-top: 81px;
  margin-bottom: 98px;
}

.send-bg-img,
.mobile-menu-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
}

.statusText {
  min-width: 75px;
}

.secondary-text-weight {
  font-weight: 700;
}

.copyIcon {
  width: 12px;
  height: 12px;
}
.header-mobile-wallet-section {
  display: flex;
  justify-content: flex-end;
  min-width: 79px;
  gap: 5px;
  height: 50px;
}

.header-mobile-wallet-account {
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff10;
  border-radius: 12px;
  border: 1px solid #ffffff50;
}

.connect-wallet-mobile-container {
  position: absolute;
  top: 110%;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 200px;
  /* height: 110px; */
  /* background-image: linear-gradient(rgba(66, 53, 144, 1), rgba(32, 21, 101, 1), rgba(255, 255, 255, 0.5)); */
  /* backdrop-filter: blur(5px); */
  background: linear-gradient(180deg, #423591 0%, #201565 100%),
linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));

  border-radius: 12px;
  padding: 20px;
  border: 0.5px solid rgba(255, 255, 255, 0.5);
}


.disabled-wallets-mobile-container .connect-wallet-mobile-row,
.disabled-wallets-mobile-container .connect-wallet-mobile-row:hover {
  cursor: not-allowed;
  /* background-color: rgba(255, 255, 255, 0.05); */
  color: rgba(255, 255, 255, 0.5);
  user-select: none;
}

.disabled-wallets-mobile-container
  .connect-wallet-mobile-row
  .connect-wallet-row-image-circle,
.disabled-wallets-mobile-container
  .connect-wallet-mobile-row
  .connect-wallet-row-image-circle:hover {
  opacity: 0.6;
  filter: grayscale(100%);
}

.disabled-wallets-mobile-container
  .connect-wallet-mobile-row
  .connect-wallet-row-text,
.disabled-wallets-mobile-container
  .connect-wallet-mobile-row
  .connect-wallet-row-text:hover {
  color: rgba(255, 255, 255, 0.5)
}

.disabled-wallets-mobile-container .disabled-message {
  font-size: 12px;
  font-weight: 500;
  font-family: Satoshi-Medium;
  color: rgba(218, 211, 255, 1);

  opacity: 0.8;
  margin-bottom: 0;
}

.connect-wallet-mobile-container .divider {
  margin: 14px 0;
  height: 1px;
  width: 100%;
  background-color: rgba(114, 108, 149, 1);
}

.connect-wallet-mobile-row {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.connect-wallet-mobile-row:not(:first-child) {
  margin-top: 14px;
}

.connect-wallet-row-image-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #ffffff;
}

.connect-wallet-row-image {
  width: 22px;
  height: 22px;
}

.connect-wallet-row-text {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  margin-left: 12px !important;
  color: #ffffff;
  margin-bottom: 0 !important;
}

.metamaskBtn {
  border-radius: 2rem;
  background-color: #ffffff;
  border-width: 0px;
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease;
}
.metamaskBtn img {
  height: 16px;
  width: 16px;
}

.select-source-modal-size {
  width: 474px;
  height: 646px;
}

@media only screen and (max-width: 600px) {
  .select-source-modal-size {
    width: 100%;
  }

  .select-source-modal-max-width {
    max-width: 474px;
  }
}

.header-wallet-button {
  position: relative;
  outline: none;
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 12px;
  /*background-color: #9281FF;*/
}

.header-mobile-button-img-1 {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 13px;
  left: 11px;
}

.header-mobile-button-img-2 {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 11px;
  right: 10px;
}

.header-app-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 79px;
  height: 50px;
  outline: none;
  border: none;
  border-radius: 80px;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-decoration: none;
  color: #ffffff;
}

.header-mobile-button-background {
  background-image: linear-gradient(90deg, #9281ff, #715aff);
  box-shadow: inset white 0px 0px 7px;
}

.header-mobile-button-background:hover {
  box-shadow: 0px 0px 30px 4px #ffffff50, 0px -1px 6px 0px #ffffffcf inset,
    0px 2px 4px 0px #ffeec159 inset !important;
}

.noRoundRight {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.noRoundLeft {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.bold {
  font-family: 'Satoshi-Bold';
}

.header-tabs {
  display: flex;
}

.header-padding-inline-home-page {
  padding-inline: 40px;
}

.send-bg-img-mobile {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
}

.header-padding-inline {
  padding-inline: 0;
}

.header-mt {
  margin-top: 28px !important;
}

.footer-icons-display {
  display: flex;
}

.footer-text-display {
  display: flex;
}

.footer-left-padding {
  padding-left: 5px;
}

@media only screen and (max-width: 767px) {
  .header-tabs {
    display: none;
  }

}

@media only screen and (max-width: 480px) {
  .header-tabs {
    display: none;
  }
  .header-padding-inline-home-page {
    padding-inline: 23px !important;
  }
  .header-mt {
    margin-top: 30px !important;
  }
  .footer-text-display {
    width: 100%;
    justify-content: space-between;
  }
  .footer-left-padding {
    padding-left: 0;
  }

  .footer-icons-display {
    display: none;
  }
  
  .footer-container-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .mainSend {
    padding-inline: 20px;
  }
  .formBg {
    background: transparent;
    margin-bottom: 0;
  }
  .formBg::before {
    display: none;
  }
  .formBody {
    padding: 0;
  }
  .send-bg-img-mobile {
    display: block;
  }
  .send-bg-img {
    display: none;
  }
  .page-padding-bottom {
    padding-bottom: 23px !important;
  }
  .transferInfoTitle {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
  }
  .separator {
    margin: 32px 0;
  }
  .send-page-footer,
  .progress-page-footer-container {
    position: absolute;
    bottom: 23px;
    padding-left: 14px;
    padding-right: 26px;
  }
  .send-page-footer {
    position: absolute;
    bottom: 23px;
    padding-left: 14px;
    padding-right: 26px;
  }
  .info-div-padding {
    padding-right: 8px !important;
  }
  .circle-bg {
    background-color: #20184d !important;
  }
  .modal-body {
    padding-inline: 0;
  }
  .formModalBg {
    margin-bottom: 0;
  }
  .header-ml {
    margin-left: 2px;
    padding-left: 3px;
  }
  .menu-header-ml {
    margin-left: 2px;
  }
  .gradient-border-mask::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 600px;
    border-radius: 20px !important;
    border-bottom-left-radius: unset !important;
    border-bottom-right-radius: unset !important;
    border-top: 1px solid transparent !important;
    border-left: 1px solid transparent !important;
    border-right: 1px solid transparent !important;
    border-bottom: 0px solid transparent !important;
    background: linear-gradient(359deg, transparent, rgba(255, 255, 255, 0.5))
      border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
  .gradient-border-mask-connect-wallet::before {
    content: none;
  }
}

.toast-position {
  top: 6rem !important;
}
